.home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.home_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 6rem;
    width: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.home_head_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home_head_name h1 {
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    text-transform: uppercase;
    color: black;
}

.home_head_name h1:hover {
    /* color: white; */
    transition: transform 0.25s ease-in-out;
    cursor: pointer;
}

.home_head_icon {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin: 1rem 0rem;
}

.home_head_icon:hover {
    /* filter: invert(1); */
    transition: transform 0.25s ease-in-out;
    cursor: pointer;
}

.home_head_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.home_head_links h1 {
    margin: 0 0.5rem;
    width: auto;
    height: auto;
    color: black;
}

.home_head_links h1:hover {
    /* color: white; */
    transition: transform 0.25s ease-in-out;
}

.home_body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1500px;

    margin: 0 auto;
    width: 100vw;
    height: 100%;
}

.home_footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    width: auto;
}

.home_footer p {
    margin: 0;
    width: auto;
    color: black;

    font-family: 'Italiana';
    font-style: normal;

    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    text-transform: uppercase;

    cursor: pointer;

    text-align: center;
    white-space: pre-wrap;
}

.home_footer a {
    text-decoration: none;
}

.home_footer p:hover {
    color: rgb(114, 114, 114);
    transition: transform 0.25s ease-in-out;
}

@media screen and (max-width: 768px) {
    .home_head {
        flex-direction: column;
    }

    .home_head_name {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}